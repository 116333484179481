.stripe-credit-card {
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #b2b8c8;
  border-radius: 4px;
  padding: 9px 12px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  box-sizing: content-box;
}

.stripe-credit-card--focus {
  border-color: #7c60d9;
  box-shadow: 0 0 4px 0 #7c60d9;
}

.stripe-credit-card--invalid {
  background-color: #faf1f0;
  border-color: #e45c52;
  border-width: 2px;
  box-shadow: none;
  padding: 8px 12px;
}

.stripe-credit-card--complete,
.stripe-credit-card--webkit-autofill {
  border-color: #6dcc6a;
  border-width: 2px;
  box-shadow: none;
  padding: 8px 12px;
}
